.column {
  padding-left: 0.8em;
  padding-right: 0.8em;
}

.half {
  flex-basis: 5%;
}

.one {
  flex-basis: 10%;
}

.two {
  flex-basis: 20%;
}

.three {
  flex-basis: 30%;
}

.four {
  flex-basis: 40%;
}

.five {
  flex-basis: 50%;
}

.six {
  flex-basis: 60%;
}

.seven {
  flex-basis: 70%;
}

.eight {
  flex-basis: 80%;
}

.nine {
  flex-basis: 90%;
}

.ten {
  flex-basis: 100%;
}

@media only screen and (max-width: 768px) {
  .halfMedium {
    flex-basis: 5%;
  }

  .oneMedium {
    flex-basis: 10%;
  }

  .twoMedium {
    flex-basis: 20%;
  }

  .threeMedium {
    flex-basis: 30%;
  }

  .fourMedium {
    flex-basis: 40%;
  }

  .fiveMedium {
    flex-basis: 50%;
  }

  .sixMedium {
    flex-basis: 60%;
  }

  .sevenMedium {
    flex-basis: 70%;
  }

  .eightMedium {
    flex-basis: 80%;
  }

  .nineMedium {
    flex-basis: 90%;
  }

  .tenMedium {
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .halfSmall {
    flex-basis: 5%;
  }

  .oneSmall {
    flex-basis: 10%;
  }

  .twoSmall {
    flex-basis: 20%;
  }

  .threeSmall {
    flex-basis: 30%;
  }

  .fourSmall {
    flex-basis: 40%;
  }

  .fiveSmall {
    flex-basis: 50%;
  }

  .sixSmall {
    flex-basis: 60%;
  }

  .sevenSmall {
    flex-basis: 70%;
  }

  .eightSmall {
    flex-basis: 80%;
  }

  .nineSmall {
    flex-basis: 90%;
  }

  .tenSmall {
    flex-basis: 100%;
  }
}
