.hero {
  min-height: 360px;
  background-image: url("../../resources/hero-1920.jpg");
  background-position-y: -5em;
  background-attachment: fixed;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  font-family: "Tangerine", cursive;
  font-size: 4em;
  position: absolute;
  color: white;
  text-shadow: 2px 2px 5px #0000003d;
  top: 0.3em;
  left: 0.5em;
}

@media only screen and (max-width: 768px) {
  .hero {
    background-attachment: unset;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
