.blockquote {
  font-family: Georgia, serif;
  font-size: 1.3em;
  font-style: italic;
  width: 100%;
  margin: 0.25em 0;
  padding: 0.35em 40px;
  line-height: 1.45;
  position: relative;
  color: #383838;
}

.blockquote:before {
  display: block;
  padding-left: 10px;
  content: "\201C";
  font-size: 80px;
  position: absolute;
  left: -20px;
  top: -20px;
  color: #7a7a7a;
}

.cite {
  color: #999999;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

.cite:before {
  content: "\2014 \2009";
}

.quoteimage {
  width: 100%;
  clip-path: circle(46% at center);
  background-color: white;
}
