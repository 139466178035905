.contentwrapper {
  max-width: 72rem;
  display: flex;
  justify-content: center;
}

.contentwrappersmall {
  max-width: 32rem;
  display: flex;
}

@media (max-width: 760px) {
  .contentwrapper {
    display: unset;
  }
}
.green {
  background-color: gray;
}

.brown {
  background-color: #fdf2e7;
}

.black {
  background-color: black;
  color: white;
}

.nomarginbottom {
  margin-bottom: 0px !important;
}

.nopaddingbottom {
  padding-bottom: 0px !important;
}

.nopaddingtop {
  padding-top: 0px !important;
}

/* colors */

.colorwhite {
  color: white;
}

.linkwhite {
  color: white;
  /* text-decoration: none; */
}

.me {
  color: #00b5a2;
  text-decoration: none;
}

.me:hover {
  text-decoration: underline;
}

.iconhref {
  background-repeat: no-repeat;
  background-position: 0 5px;
  background-size: 14px 14px;
  padding-left: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='80' height='80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='402' width='582' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath fill='%23ffffff' id='svg_2' d='m29.298,63.471l-4.048,4.02c-3.509,3.478 -9.216,3.481 -12.723,0c-1.686,-1.673 -2.612,-3.895 -2.612,-6.257s0.927,-4.585 2.611,-6.258l14.9,-14.783c3.088,-3.062 8.897,-7.571 13.131,-3.372c1.943,1.93 5.081,1.917 7.01,-0.025c1.93,-1.942 1.918,-5.081 -0.025,-7.009c-7.197,-7.142 -17.834,-5.822 -27.098,3.37l-14.901,14.784c-3.575,3.549 -5.543,8.269 -5.543,13.293s1.968,9.743 5.544,13.292c3.679,3.65 8.51,5.474 13.343,5.474c4.834,0 9.667,-1.824 13.348,-5.476l4.051,-4.021c1.942,-1.928 1.953,-5.066 0.023,-7.009c-1.927,-1.941 -5.068,-1.952 -7.011,-0.023zm45.156,-57.427c-7.73,-7.67 -18.538,-8.086 -25.694,-0.986l-5.046,5.009c-1.943,1.929 -1.955,5.066 -0.025,7.009c1.93,1.943 5.068,1.954 7.011,0.025l5.044,-5.006c3.707,-3.681 8.561,-2.155 11.727,0.986c1.688,1.673 2.615,3.896 2.615,6.258c0,2.363 -0.928,4.586 -2.613,6.259l-15.897,15.77c-7.269,7.212 -10.679,3.827 -12.134,2.383c-1.943,-1.929 -5.08,-1.917 -7.01,0.025c-1.93,1.942 -1.918,5.081 0.025,7.009c3.337,3.312 7.146,4.954 11.139,4.954c4.889,0 10.053,-2.462 14.963,-7.337l15.897,-15.77c3.574,-3.549 5.544,-8.27 5.544,-13.294c0,-5.022 -1.97,-9.743 -5.546,-13.294z'/%3E%3C/g%3E%3C/svg%3E");
}
