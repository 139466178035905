.card {
  background-color: #00b5a2;
  color: white;
  padding: 2em !important;
  border-radius: 4px;
}

.card-link {
  color: gainsboro;
}
